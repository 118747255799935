<template>
  <main class="h-screen w-screen bg-white">
  <div class="min-h-screen flex flex-col">
    <div class="p-1 sm:p-0 container mx-auto grow max-w-10xl">
        <slot/>
    </div>
  </div>
  </main>
</template>
<script setup lang="ts">
</script>